@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.signup-container {
  width: 100%;
  margin-top: 100px;
}

@media only screen and (min-width: $xs-screen) {
  .signup-container {
    display: flex;
  }
}

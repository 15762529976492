@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.signup-main {
  order: 1;
  flex: 5;
  padding: 0 15px 45px 15px;

  &__facebook {
    text-align: center;
  }

  &__icon {
    float: left;
    padding-top: 3px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }

  .btn-facebook {
    width: 280px;
    font-size: var(--s0);
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &__oroption {
    color: $green;
    text-align: center;
    border-bottom: 1px solid $green;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__title {
    color: $dark-blue;
    font-weight: 600;
    font-size: var(--s0);
    border-bottom: 1px solid $dark-blue;
    margin: 0 0 32px;
  }

  &__text {
    background-color: white;
    display: inline-block;
    padding: 0 5px;
    position: relative;
    top: 10px;

    &--title {
      top: 5px;
      padding: 0 3px 0 0;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 2em;
  }

  &__headertext {
    text-align: left;
    margin: 0.5em 8em 3em 8em;
  }

  @media only screen and (max-width: $xs-screen) {
    &__header {
      margin-bottom: 1em;
    }
  }
}

@media only screen and (max-width: $xs-screen) {
  .signup-main {
    margin: 1em 0 0;

    &__headertext {
      margin: 0;
    }
  }

}

@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.password-reset-page {
  overflow: hidden;
}

.password-reset {
  margin: 75px auto 40px;
  padding: 10px;
  max-width: 600px;
  min-width: 320px;

  &__heading {
    margin: 30px 0;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__btn {
    margin: 20px 0;
  }
  &__input > .formfield > input {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 641px) {
  .password-reset {
    margin: 100px auto 40px;
  }
}

@media only screen and (max-width: 640px) {
  .password-reset {
    margin: 40px auto;
  }
}

@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "variables" as *;
.profile-nav {
  padding: 1em;

  &__link {
    font-size: 22px;
    color: $portal-grey;
    margin-right: 1.5em;
    font-weight: 500;
    white-space: nowrap;

    &--active {
      color: $text-color;
      border-bottom: 3px solid $portal-blue;
      font-weight: bold;
    }
  }

  &__text {
    display: inline-block;
  }

  &__text-mobile {
    display: none;
  }
}

@media screen and (max-width: $xs-screen) {
  .profile-nav {
    padding-top: 1.5em;

    &__mobile {
      padding: 0.7em !important;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__link {
      font-size: 12px;
      text-transform: uppercase;
      margin-right: 0.5em;
      position: relative;
    }
  }
}

@include not-large-screen {
  .profile-nav {
    &__mobile {
      padding: 0.7em !important;
    }

    &__text {
      display: none;
    }

    &__text-mobile {
      display: inline-block;
    }
  }
}

@use "../../settings/all" as *;
@use "../../mixins/all" as *;
input[type=checkbox], input[type=radio] {
  margin-top: 0;
}

.form-control-custom {
  border-radius: 5px;
  height: 46px;
  font-weight: 500;

  &.signup-form__input {
    margin-bottom: 0;
  }

  &:focus {
    background: $white;
    border-color: $input-background;
  }
}

textarea {
  resize: none;
}

textarea.form-control.form-control-custom {
  height: 105px;
}

.portal {
  &__form-group {
    padding: 1em;
    border-top: 1px solid rgba($portal-grey, 0.6);
    display: flex;
    align-items: center;
    margin: 0;

    .control-label--portal {
      padding: 0;
    }

    &__input {
      flex: 1;
      margin-right: 10%;

      @include mobile-screen {
        margin-right: 0;
      }

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  &__label--with-info {
    display: flex;
    flex-direction: column;

    small {
      margin-top: 8px;
    }
  }
}

.errorlist {
  color: $red;
}

.form-control {
  color: black;
}


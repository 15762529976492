@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.signup-sidebar {
  background-size: cover;
  background: $text url(../img/signup/signupbg02.png) fixed;
  position: fixed;
  top: $nav-height;
  width: 300px;
  height: 100vh;

  & + * {
    margin-left: 300px !important;
  }

  &__heading {
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    margin: 30px;
  }

  &__table {
    margin: auto;
    color: #fff;
    font-size: var(--s0);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &__table-row {
    line-height: 54px;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__text {
    padding-left: 15px;
  }
}

@media only screen and (max-width: $xs-screen) {
  .signup-sidebar {
    background-image: none;
    width: 100%;
    height: 300px;
    position: initial;

    & + * {
      margin-left: 0 !important;
    }

    &__heading {
      padding-top: 40px;
    }
  }

  .signup-container.signup-container--first-step > .signup-sidebar > .signup-sidebar__img {
    display: none;
  }
}

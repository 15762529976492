@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  margin-top: 100px;
  align-items: center;

  &__container {
    max-width: $page-width;
    width: 100%;
  }

  .btn--grey {
    color: darkgrey;
  }
}

.login-main {
  margin: $nav-height auto;
  width: 100%;

  &__title {
    text-align: center;
  }

  &__text {
    margin: 1em 0;

    a {
      color: $text;
      text-decoration: underline;
    }
  }

  &__no-account {
    margin-top: 5em;
  }

  &__facebook {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  &__oroption {
    margin: 0 15px 40px;
  }
}

.login-form {
  margin: 20px auto;
  padding: 15px 0;
  max-width: 400px;

  .form-group {
    margin-bottom: 4em;
  }
}

@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.page--teacher-signup {
  background-color: $bg-grey;
}

.teacher-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 5% 50px 5%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $page-width;
    width: 100%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    padding: 48px 74px;
    max-width: 900px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
    margin-top: 40px;
  }

  &__header {
    font-size: 35px;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    color: $dark-blue;
  }

  &__info {
    margin-top: 70px;
  }

  &__subheader {
    text-align: center;
    font-size: 20px;
    line-height: 31px;
    color: black;
    margin-bottom: 30px;
  }

  &__perks {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    max-width: 900px;
    text-align: center;

    & > span {
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
        width: 30px;
        height: 30px;
      }

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  &__perk-text {
    font-size: var(--s1);
    color: $dark-blue;
    line-height: 28px;
    font-weight: bold;
  }

  &__section-title {
    font-weight: bold;
    font-size: var(--s1);
    line-height: 28px;
    color: $dark-blue;
    margin-bottom: 16px;

    &--no-margin {
      margin-bottom: 0;

    }
  }

  &__section-explanation {
    font-size: var(--s-1);
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
  }

  &__or {
    position: absolute;
    left: calc(50% - 10px);
    top: 10px;
    font-size: var(--s0);
  }

  &__fieldset {
    width: calc(50% - 24px);

    &:nth-of-type(odd) {
      margin-right: 20px;
    }
  }

  &__form {
    label {
      top: -46px !important;
      font-weight: normal;

      &::before {
        color: $text-muted;
      }
    }

    input {
      font-size: 20px;
      padding: 4px 8px 0 10px;

      &:focus + label {
        &::before {
          color: $primary-blue;
        }
      }
    }

    input + label + .errorlist {
      margin: -25px 0 20px 0;
    }

    .form-control-custom:focus {
      box-shadow: none;
      border: 2px solid $primary-blue;
    }

    .formfield {
      position: relative;
    }
  }

  &__checkbox {
    .form-group {
      width: 100% !important;
    }

    label {
      display: flex;
      align-items: center;
      font-size: var(--s0);

      input {
        margin-bottom: 2px;
      }
    }

  }

  &__bottom {
    display: flex;
    justify-content: center;
    margin: 40px 0 0;
  }

  .btn {
    &__file-upload {
      display: flex;
      align-items: center;
      align-self: flex-start;
      border-color: $primary-blue;
      color: $primary-blue;
      padding: 4px 14px;

      i {
        color: $primary-blue;
      }

      &:hover {
        background: $white;
        color: $primary-blue;
      }

      i {
        margin-right: 4px;
      }
    }
  }

  .file-upload {
    height: auto;
    width: 100%;
    border: none;
    padding: 0;

    &__label {
      display: none;
      height: auto;
      color: $text-muted;
      font-weight: normal;
      max-width: 300px;
    }

    &__label-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__delete {
      margin-left: 10px;
      display: none;
    }

    &__text {
      font-size: 22px;
    }

    &__selected {
      .file-upload__delete {
        display: block;
        color: $text-muted;
      }

      .file-upload__label {
        display: flex;
        align-items: center;
      }

      .fa-upload {
        display: none;
      }

      .file-upload__text {
        display: none;
      }
    }
  }

  .choices {
    &__list--multiple {
      .choices__item {
        background-color: rgba($grey, 0.2);
        border: 1px solid $bg-grey;
        color: black;
        border-radius: 30px;
        font-size: var(--s0);
        margin-top: 4px;
      }

      .choices__button {
        border-left: none;
        background-size: 12px;
        margin-right: 0;
      }
    }

    &__inner {
      border-radius: 5px;
      padding: 4px 8px 0 10px !important;
    }

    &__item {
      font-size: 20px;
    }

    &__input {
      padding: 0;
      min-width: 50%;
    }

    & + label {
      position: absolute;
      top: 58px !important;
    }
  }

  @include xxs-screen {
    &__card {
      padding: 20px;
      margin-top: 10px;
    }

    &__header {
      font-size: 22px;
      line-height: 27px;
    }

    &__subheader {
      display: none;

      &--mobile-visible {
        display: block;
      }
    }

    &__fieldset {
      width: 100%;
      margin: 0;

      &:nth-of-type(odd) {
        margin-right: 0;
      }
    }

    &__or {
      top: 60px;
    }

    &__perks {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      & > span {
        margin: 0 0 10px 0;
        justify-content: center;

        img {
          width: 26px;
          height: 26px;
        }

        &:not(:last-of-type) {
          margin-right: 0px;
        }
      }
    }

    .btn {
      &__file-upload {
        align-self: center;
        margin: 10px 0 26px 0;
      }
    }

    .choices {
      margin-bottom: 30px;
    }

    &__form {
      label + .errorlist {
        margin: -30px 0 20px 0;
      }
    }
  }
}



@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.login-container {

  &__logout-text {
    margin-bottom: 12px;
  }

  input {
    font-size: var(--s1);
    border-radius: 5px;
    padding: 28px 14px;
  }

  .btn.btn__round {
    font-size: 20px;
    border-width: 1px;

    &--small {
      font-size: var(--s0);
    }

    &--inverse {
      background: $white;
      color: $dark-blue;
      border-color: $dark-blue;
    }

    &:hover {
      border: 1px solid $dark-blue;
    }
  }
}


.signup-main {
  &__title {
    display: flex;
    border-color: $secondary-green;
  }
}


.signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 0 15px;

  legend {
    margin-bottom: 40px;
  }

  .formfield {
    margin-bottom: 10px;
  }

  .choices {
    text-align: left;

    &__inner {
      display: flex;
      align-items: center;
      height: 58px;
    }

    input {
      padding: 0;
    }
  }

  .is-open {
    .choices__list {
      display: block;
    }
  }

  &__row {
    &--box {
      margin-bottom: 30px;
    }
  }

  &__remove-location {
    margin-top: 5px;
  }

  &__submit {
    width: 280px;
    font-weight: 600;
    font-size: var(--s0);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    box-shadow: inset 0 0 0 #1aa48f, 0 2px 0 0 #199c87;
  }

  &__login-block {
    display: flex;
    margin: 40px auto 0;
    justify-content: center;
    align-items: center;
  }

  &__login-description {
    font-size: var(--s1);
    margin: 0;
    padding: 0 12px;
  }

  &__login {
    font-weight: 600;
    color: $light-text;
  }

  &__submit-icon {
    padding-left: 6px;
  }

  &__bottom {
    text-align: center;
    margin: 30px 0 0;
  }

  .form-control, .choices__inner {
    border: 1px solid $dark-gray;
    border-radius: 5px;

    &:focus {
      box-shadow: none;
    }
  }

  &__input,
  select.form-control {
    & + label {
      &[placeholder] {
        display: flex;
        position: relative;
        top: -60px;

        @include float-label-before;
      }
    }

    box-sizing: border-box;
    height: calc(3em + 2px);
    margin: 0 0 1em;
    background: #fff;
    resize: none;
    outline: none;
    border-radius: 5px;

    &--select {
      border-radius: 1px;
      appearance: none;
      background: url(../img/searchpage/dropdownarrow.png) no-repeat 95% 50%;
      background-size: 12px;
      outline: none;
    }

    &:focus {
      border-color: $green;
      outline: none !important;
    }
  }

  textarea.form-control {
    @include floating-label;
    position: relative;
    box-sizing: border-box;
    margin: 0 0 4.5em;
    background: #fff;
    outline: none;

    & + label[placeholder]:before {
      position: absolute;
    }
  }

  &__input {
    padding: 1em;
  }

  &__location-formset {
    position: relative;
  }

  &__add-location {
    position: absolute;
    bottom: 21px;
    right: 0;
  }

  &__personalia, &__credentials, &--margin-bottom {

    @include medium-screen {
      margin-bottom: 1.5em;
    }
  }
}

.choices + label[placeholder] {
  margin-top: calc(-4em - 12px) !important;
}

@media only screen and (max-width: $xs-screen) {
  .signup-form {
    &__add-location {
      position: relative !important;
      margin-top: 4px;
      margin-bottom: 10px;
    }
  }
}



@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.page--referral {
  .referral__signup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &__role {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;

      img {
        width: 80%;
      }

      a {
        margin-top: 10px;
        width: 60%;
      }

      .tutor_image {
        position: relative;
        top: -15px;
      }
    }

    @include mobile-screen {
      flex-direction: column;

      &__role {
        margin-bottom: 10%;

      }
    }
  }
}

.referral {
  &__help-text {
    margin-top: 48px;
    text-align: center;
  }
}
